import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby';
import firebaseApp from 'firebase/compat/app';

import 'firebase/compat/auth';
import firebaseConfig from 'contexts/firebase/config';
import { Box, Center, Container, Spinner, Text } from '@chakra-ui/react';
import Button from 'components/Button'
import Social from 'containers/HomePage/Social'
import useFirestoreUser from 'hooks/useFirestoreUser';

firebaseApp.initializeApp(firebaseConfig);

const Post = () => {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const { data } = useFirestoreUser(isSignedIn?.uid || 0)
  // Local signed-in state.
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged(user => {
      setIsSignedIn(user);
    });
    return () => {
      unregisterAuthObserver()
    } // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  if (data && !data[0]?.isAdmin) navigate('/')
  if (!data?.[0]?.isAdmin) return <Center><Spinner /></Center>
  return (
    <Box bg="white" pb="5em">
      <Container>
        <Box my="1em" textAlign={'center'} >
          <Button
            variant="transparent"
            onClick={() => {
              firebaseApp.auth().signOut()
              navigate('/')
            }}
          >Sign-out</Button>
        </Box>
        <Text textAlign={'center'}>Welcome {firebaseApp.auth().currentUser?.displayName}!</Text>
        <Social isAdmin />
      </Container>
    </Box>
  )
}

export default Post

import { collection, getFirestore, query, where } from 'firebase/firestore';
import { useFirestoreCollectionData } from 'reactfire'
import { initializeApp } from "firebase/app";
import firebaseConfig from 'contexts/firebase/config';

const app = initializeApp(firebaseConfig);

const useFirestoreUser = (id) => {
  const db = getFirestore(app);
  const userRef = collection(db, "users");
  return useFirestoreCollectionData(query(userRef, where("uid", "==", id)), { idField: 'id' })
}

export default useFirestoreUser
